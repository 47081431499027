<script>
import constants from "@/constants";

export default {
  created() {
    const { token, gborgid } = this.$route.query;
    localStorage.setItem("accessToken", token);
    this.$store.dispatch("user/fetchUserDetailsByToken", token).then((res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      let routingUrl = `${constants.url}/${gborgid}`;
      if (this.$route.hash) {
        routingUrl = routingUrl + this.$route.hash;
      }
      window.location.href = routingUrl;
    });
  },
};
</script>
